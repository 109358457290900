import React from 'react'

import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const NotFound: React.FC<React.PropsWithChildren> = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            mt: '5%',
            height: '100%',
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'white',
          }}
        >
          <Typography
            variant="h3"
            color="text.primary"
            sx={{ fontWeight: 'bolder' }}
          >
            {'Erro 404'}
          </Typography>
          <Box my={2} />
          <Typography variant="subtitle1" color="text.primary">
            {'A página que você está procurando não foi encontrada.'}
          </Typography>
          <Box my={1} />
          <Box sx={{ mb: 6 }}></Box>
          <Link to="dashboard">
            <Button
              onClick={() => (window.location.href = '/dashboard')}
              sx={{ width: '300px' }}
              fullWidth
            >
              Voltar
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  )
}
export default NotFound
